import {
  DatePicker,
  Drawer,
  message,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Spin,
  Table,
  TimePicker,
  Tooltip,
} from 'antd'
import { SyncOutlined } from '@ant-design/icons'

// message & notification
message.config({ top: 38 }) // 为了不遮挡抽屉的关闭
notification.config({ top: 38 }) // 与上统一

// Spin
Spin.setDefaultIndicator(<SyncOutlined spin />)

// Tooltip
Tooltip.defaultProps = Tooltip.defaultProps || {}
Tooltip.defaultProps.mouseEnterDelay = 0.3

// Popover
Popover.defaultProps = Popover.defaultProps || {}
Popover.defaultProps.mouseEnterDelay = 0.3

// Popconfirm
Popconfirm.defaultProps = Popconfirm.defaultProps || {}
Popconfirm.defaultProps.mouseEnterDelay = 0.3

// Drawer & Modal
Drawer.defaultProps = Drawer.defaultProps || {}
Drawer.defaultProps.maskClosable = false
Drawer.defaultProps.keyboard = false
Modal.defaultProps = Modal.defaultProps || {}
Modal.defaultProps.maskClosable = false
Modal.defaultProps.keyboard = false

// Table
const _Table = Table as any
_Table.defaultProps = _Table.defaultProps || {}
_Table.defaultProps.size = 'middle'
_Table.defaultProps.pagination = false

// DatePicker & RangePicker & TimePicker
const _DatePicker = DatePicker as any
const _RangePicker = DatePicker.RangePicker as any
const _TimePicker = TimePicker as any
_DatePicker.defaultProps = _DatePicker.defaultProps || {}
_DatePicker.defaultProps.inputReadOnly = true
_RangePicker.defaultProps = _RangePicker.defaultProps || {}
_RangePicker.defaultProps.inputReadOnly = true
_TimePicker.defaultProps = _TimePicker.defaultProps || {}
_TimePicker.defaultProps.inputReadOnly = true

// types
declare module 'antd/es/tooltip' {
  interface AbstractTooltipProps {
    /** @default 0.3 */
    mouseEnterDelay?: number
  }
}
declare module 'antd/es/drawer' {
  interface DrawerProps {
    /** @default false */
    maskClosable?: boolean
    /** @default false */
    keyboard?: boolean
  }
}
declare module 'antd/es/modal' {
  interface ModalProps {
    /** @default false */
    maskClosable?: boolean
    /** @default false */
    keyboard?: boolean
  }
  interface ModalFuncProps {
    /** @default false */
    maskClosable?: boolean
    /** @default false */
    keyboard?: boolean
  }
}
declare module 'antd/es/table' {
  interface TableProps {
    /** @default 'middle' */
    size?: TableProps['size']
    /** @default false */
    pagination?: TableProps['pagination']
  }
}
