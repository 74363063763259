import axios from 'axios'

import type { IMerchant } from '.'
import './__mock__'

/** 获取商家列表 */
export const apiGetOptions = async (): Promise<IMerchant[]> => {
  return axios.get('/linkhub_oms/user/merchant/list').then(res =>
    (res.$data || []).map((d: IMerchant) => ({
      userId: d.userId,
      userName: d.userName || '',
      email: d.email || '',
    })),
  )
}
