import { getGlobalState, setGlobalState } from '../globalStore'
import { apiGetUserInfo } from './apis'

interface IUserInfo {
  /** 用户id */
  userId: string
  /** 用户名（可能为空串） */
  userName: string
  /** 邮箱 */
  email: string
  /** 用户角色：100-超管; 101-员工; 102-商家; 103-财务; 104:仓库 */
  roleCodes: string[]
  /** 用户角色：100-超管; 101-员工; 102-商家; 103-财务; 104:仓库 */
  roleCodeMap: { [roleCode: string]: string | undefined }
  /** 是否在白名单里（仅限商家角色，其它角色为 null） */
  vip: boolean | null
}

let pendingPromise: Promise<IUserInfo> | null = null

/** 获取用户信息（整个页面会话只会发一次请求，重复调用只会取已有结果或缓存的 pendingPromise） */
const fetchUserInfo = async (): Promise<IUserInfo> => {
  const { userInfo } = getGlobalState()
  if (userInfo) return userInfo // 已有结果
  if (pendingPromise) return pendingPromise.then() // 请求中

  pendingPromise = apiGetUserInfo()
    .then(data => {
      pendingPromise = null
      setGlobalState({ userInfo: data })
      return data
    })
    .catch(err => {
      pendingPromise = null
      throw err
    })

  return pendingPromise.then()
}

export type { IUserInfo }
export { fetchUserInfo }
