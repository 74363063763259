import { ReactNode } from 'react'

import { PageEmpty } from '@/components/PageEmpty'
import { useGlobalState } from '@/globalStore/globalStore'

export const Auth = ({ codes, children }: { codes: [string, ...string[]]; children: ReactNode }) => {
  const codeMap = useGlobalState(s => s.userInfo?.roleCodeMap) || {}

  if (codes.some(code => codeMap[code])) return <>{children}</>

  return <PageEmpty type="noPermission" />
}
