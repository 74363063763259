/* eslint-disable simple-import-sort/imports */
// 依据 node_modules/.vite/deps/*.js，@chunks稳定性
import {
  I18nContext,
  I18nextProvider,
  Trans,
  TransWithoutContext,
  Translation,
  composeInitialProps,
  date,
  getDefaults,
  getI18n,
  getInitialProps,
  initReactI18next,
  number,
  plural,
  select,
  selectOrdinal,
  setDefaults,
  setI18n,
  time,
  useSSR,
  useTranslation,
  withSSR,
  withTranslation,
} from 'react-i18next'

export default {
  I18nContext,
  I18nextProvider,
  Trans,
  TransWithoutContext,
  Translation,
  composeInitialProps,
  date,
  getDefaults,
  getI18n,
  getInitialProps,
  initReactI18next,
  number,
  plural,
  select,
  selectOrdinal,
  setDefaults,
  setI18n,
  time,
  useSSR,
  useTranslation,
  withSSR,
  withTranslation,
}
