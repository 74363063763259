import i18next from 'i18next'
import { create } from 'zustand'
import _ from 'lodash'

import { _setAppLangToLocalStorage, getAppLangByLocalStorage } from '@/globalStore/getLang'
import type { IUserInfo } from './userInfo'

interface IGlobalState {
  /** 语言 */
  lang: 'en' | 'zh'
  /** 用户信息 */
  userInfo: IUserInfo | null
}

/** 初始状态 */
const initialGlobalState = Object.freeze<Required<IGlobalState>>({
  lang: getAppLangByLocalStorage('initialGlobalState'),
  userInfo: null,
})

const useStore = create<IGlobalState>(() => initialGlobalState)

/** 响应式精细化状态选择 */
const useGlobalState = function <T>(selector: (state: IGlobalState) => T): T {
  return useStore(selector)
}

/** 获取状态 */
const getGlobalState = function () {
  return useStore.getState()
}

/** 设置状态 */
const setGlobalState = function (state: Partial<IGlobalState> | ((prevState: IGlobalState) => Partial<IGlobalState>)) {
  const prevState = useStore.getState()
  const newState = typeof state === 'function' ? state(prevState) : state
  if (prevState === newState) return
  if (newState === initialGlobalState) {
    useStore.setState(initialGlobalState, true)
  } else {
    useStore.setState(_.pick(newState, Object.keys(initialGlobalState)))
  }
}

/** 监听状态 */
const subscribeGlobalState = useStore.subscribe

const log = (state: unknown, prevState?: unknown) => {
  if (import.meta.env.DEV) {
    console.groupCollapsed('globalState')
    console.log('state:', state)
    console.log('prevState:', prevState)
    console.groupEnd()
  }
}
useStore.subscribe((v1, v2) => {
  if (v1.lang !== v2.lang) {
    _setAppLangToLocalStorage(v1.lang) // 同步 lang 到 localStorage
    i18next.changeLanguage(v1.lang) // 切换语言包
  }
  log(v1, v2) // 状态变更打印
})

export type { IGlobalState }
export { initialGlobalState, useGlobalState, getGlobalState, setGlobalState, subscribeGlobalState }
