/* eslint-disable simple-import-sort/imports */
// 依据 node_modules/.vite/deps/*.js，@chunks稳定性
import vReact from './v-react'
import vUtils from './v-utils'

const modules = {
  vReact,
  vUtils,
}

// 防止 treeshake
if (!modules) window.__temp = modules
