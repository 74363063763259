import { CSSProperties } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { Spin } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import styles from './styles.module.less'

/** 延迟显示加载效果的时间（防止闪烁） */
export const SPIN_DELAY = 500

/** 块级 loading（默认） | 全屏 loading */
export const Loading = ({
  type,
  delay,
}: {
  /** @default 'block' */
  type?: 'block' | 'fullscreen'
  /** @default 500 */
  delay?: number
}) => {
  if (type === 'fullscreen') {
    return createPortal(<Spin fullscreen delay={delay ?? SPIN_DELAY} />, document.body)
  }

  return (
    <div className={styles.loading_block}>
      <Spin delay={delay ?? SPIN_DELAY} />
    </div>
  )
}

/** loading 图标 */
export const LoadingIcon = ({ style, className }: { style?: CSSProperties; className?: string }) => {
  return <SyncOutlined spin style={style} className={classNames(styles.loadingIcon, className)} />
}
