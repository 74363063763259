import { t } from 'i18next'
import axios, { AxiosRequestConfig } from 'axios'

export const SHOW_ERR_MSG_PREFIX = 'SHOW_ERR_MSG_PREFIX::'

export const getPath = (config: AxiosRequestConfig) => {
  let path = axios
    .getUri(config)
    .split(/[?#]+/)[0]
    // 去除 origin，参考 https://github.com/axios/axios/blob/v1.6.7/lib/helpers/isAbsoluteURL.js
    .replace(/^([a-z][a-z\d+\-.]*:)?\/\/[^/]+/i, '')
    // 去除尾杠
    .replace(/\/+$/, '')

  if (path.startsWith(import.meta.env.VITE_APP_BASE_NAME)) {
    path = path.replace(import.meta.env.VITE_APP_BASE_NAME, '')
  }

  return path
}

export const getCustomServer = () => {
  return sessionStorage.getItem('oms_customServer')?.match(/^https?:\/\/[^/?#]+/i)?.[0]
}

export const getDefaultErrMsg = (code: unknown) => {
  const arrivedBackEnd = typeof code === 'string' && /^-?[1-9]\d*$/.test(code)
  return arrivedBackEnd ? t('1-error.xi-tong-yi-chang') : t('1-error.qing-qiu-yi-chang')
}
