import { useTranslation } from 'react-i18next'
import { Result } from 'antd'
import { FrownOutlined, LockOutlined } from '@ant-design/icons'

/** 404 | 无权限 */
export const PageEmpty = ({ type }: { type: 'notFound' | 'noPermission' }) => {
  if (type !== 'noPermission' && type !== 'notFound') {
    type = 'notFound'
  }

  const { t } = useTranslation()

  if (type === 'noPermission') {
    return <Result icon={<LockOutlined />} title={t('1-error.noPermission')} />
  }

  return <Result icon={<FrownOutlined />} title={t('1-error.notFound')} />
}
