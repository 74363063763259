import { useState } from 'react'
import { Button } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

/** 号码私密展示，只展示后4位 */
export const NumPrivacyShow = ({
  value,
  placeholder = '--',
}: {
  value: string | number | null | undefined
  placeholder?: string
}) => {
  value = `${value ?? ''}`

  const [privacy, setPrivacy] = useState(true)

  if (!value) return placeholder

  let v = value
  if (privacy) v = `******${v.slice(-4)}`

  return (
    <>
      {v}
      <Button
        type="text"
        icon={privacy ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        onClickCapture={e => {
          e.stopPropagation()
          setPrivacy(!privacy)
        }}
      />
    </>
  )
}
