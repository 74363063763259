const propertyDescriptor = {
  configurable: false,
  enumerable: true,
  writable: false,
}

Object.defineProperties(window, {
  $F_YM: {
    ...propertyDescriptor,
    value: 'YYYY-MM',
  },
  $F_YMD: {
    ...propertyDescriptor,
    value: 'YYYY-MM-DD',
  },
  $F_YMDH: {
    ...propertyDescriptor,
    value: 'YYYY-MM-DD HH',
  },
  $F_YMDHm: {
    ...propertyDescriptor,
    value: 'YYYY-MM-DD HH:mm',
  },
  $F_YMDHms: {
    ...propertyDescriptor,
    value: 'YYYY-MM-DD HH:mm:ss',
  },
  $C_STATUS_DEFAULT: {
    ...propertyDescriptor,
    value: 'var(--ant-color-text-placeholder)',
  },
})
