import { CSSProperties } from 'react'
import BigNumber from 'bignumber.js'
import { theme } from 'antd'

import { toThousands } from '@/utils/utils'

/** 适用列表项的金额格式化，左边币种右边金额两端对齐、千分位、两位精度 */
export const CellFormatSum = (props: {
  /** 金额 */
  value: BigNumber.Value | null | undefined
  /** 币种 @default 'USD' */
  currency?: string | null
  /** @default 'block' */
  mode?: 'block' | 'inline'
  style?: CSSProperties
  className?: string
}) => {
  const { token } = theme.useToken()
  const { mode, style, className } = props
  const currency = props.currency === undefined ? 'USD' : props.currency
  const value = toThousands(props.value, { placeholder: '' })
  return (
    <div
      style={{
        lineHeight: 1.3,
        ...style,
        gap: 6,
        alignItems: 'center',
        display: mode === 'inline' ? 'inline-flex' : 'flex',
      }}
      className={className}
    >
      {!!currency && value && (
        <span
          style={{
            flex: '0 0 auto',
            textAlign: 'left',
            fontSize: '0.9em',
            color: token.colorTextLabel,
          }}
        >
          {currency}
        </span>
      )}
      <span style={{ flex: '1 1', overflow: 'hidden', textAlign: 'right' }}>{value || '--'}</span>
    </div>
  )
}
