/* eslint-disable simple-import-sort/imports */
// 依据 node_modules/.vite/deps/*.js，@chunks稳定性
import react from 'react'
import reactDom from 'react-dom'
import reactRouter from './react-router'
import reactRouterDom from './react-router-dom'
import i18next from './i18next'
import reactI18next from './react-i18next'

export default {
  react,
  reactDom,
  reactRouter,
  reactRouterDom,
  i18next,
  reactI18next,
}
