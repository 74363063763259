import { useEffect, useState } from 'react'
import { useMemoizedFn } from 'ahooks'

/** Modal、Drawer 动画控制 */
export const useAntdPopupAnimation = (destroyPopup: () => void) => {
  const [open, setOpen] = useState(false)

  const onHide = useMemoizedFn(() => setOpen(false))
  const afterOpenChange = useMemoizedFn((open: boolean): void => void (!open && destroyPopup()))

  useEffect(() => setOpen(true), [])

  return {
    /** 传给 open */
    open,
    /** 传给 onCancel 或 onClose，可主动调用 */
    onHide,
    /** 传给 afterOpenChange，不可主动调用 */
    afterOpenChange,
  }
}
