import { ReactNode, useEffect, useMemo } from 'react'
import { useMatches } from 'react-router'
import { useTranslation } from 'react-i18next'

const SEPARATOR = ' · '

export const SetTitle = Object.assign(
  function SetTitle({ children }: { children: ReactNode }) {
    const { t } = useTranslation()
    const matches = useMatches()
    const appName = t('0-app.name')

    const name = useMemo(
      () =>
        matches
          .map(d => Object(d.handle).name?.())
          .filter(d => typeof d === 'string' && d && d.trim() !== appName)
          .reverse()
          .concat(appName)
          .slice(0, 2)
          .join(SEPARATOR),
      [appName, matches],
    )

    useEffect(() => {
      if (name) {
        document.title = name
      }
    }, [name])

    return <>{children}</>
  },

  {
    /** 标题分隔符 */
    SEPARATOR: SEPARATOR as typeof SEPARATOR,
  },
)
