/* eslint-disable simple-import-sort/imports */
// 依据 node_modules/.vite/deps/*.js，@chunks稳定性
import {
  Immer,
  applyPatches,
  castDraft,
  castImmutable,
  createDraft,
  current,
  enableMapSet,
  enablePatches,
  finishDraft,
  freeze,
  immerable,
  isDraft,
  isDraftable,
  nothing,
  original,
  produce,
  produceWithPatches,
  setAutoFreeze,
  setUseStrictShallowCopy,
} from 'immer'

export default {
  Immer,
  applyPatches,
  castDraft,
  castImmutable,
  createDraft,
  current,
  enableMapSet,
  enablePatches,
  finishDraft,
  freeze,
  immerable,
  isDraft,
  isDraftable,
  nothing,
  original,
  produce,
  produceWithPatches,
  setAutoFreeze,
  setUseStrictShallowCopy,
}
