import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { Loading } from '@/components/Loading'
import { routes } from './routes'

const router = createBrowserRouter(routes, {
  basename: import.meta.env.VITE_APP_BASE_NAME,
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_relativeSplatPath: true,
  },
  window,
})

export const RouterConfig = function () {
  return <RouterProvider router={router} fallbackElement={<Loading />} future={{ v7_startTransition: true }} />
}
