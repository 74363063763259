import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { getAppLangByLocalStorage } from '@/globalStore/getLang'
import en from './locales/en.json'
import zh from './locales/zh.json'

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'zh' // ts 提示默认为中文
    resources: {
      zh: typeof zh
      en: typeof zh
    }
  }
}

i18n.use(initReactI18next).init({
  resources: {
    zh: {
      translation: zh,
    },
    en: {
      translation: en,
    },
  },

  lng: getAppLangByLocalStorage(),

  interpolation: {
    escapeValue: false,
  },

  debug: import.meta.env.DEV,
})

document.title = i18n.t('0-app.name')
