import { CSSProperties, ReactNode, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMemoizedFn, useRequest } from 'ahooks'
import { Select, SelectProps, theme } from 'antd'

import { Loading } from '@/components/Loading'
import { selectFilterOption } from '@/utils/utils'
import { apiGetOptions } from './apis'

export interface IMerchant {
  userId: string
  userName: string
  email: string
}

/** 商家选择器（商家侧不可调用） */
export const MerchantSelect = function <T extends boolean = false>({
  style,
  className,
  multiple,
  maxTagCount,
  maxTagTextLength,
  placeholder,
  value,
  onChange,
  onList,
  getOptionLabel,
  ...rest
}: {
  style?: CSSProperties
  className?: string
  multiple?: T
  /** @default 1 */
  maxTagCount?: SelectProps['maxTagCount']
  /** @default 5 */
  maxTagTextLength?: SelectProps['maxTagTextLength']
  placeholder?: ReactNode
  disabled?: boolean
  /** @default true */
  allowClear?: boolean
  value?: T extends true ? string[] : string
  onChange?: (value: T extends true ? string[] : string | undefined) => void
  onList?: (list: IMerchant[]) => void
  getOptionLabel?: (merchant: IMerchant) => string
}) {
  const { t } = useTranslation()
  const { token } = theme.useToken()
  const initialFlagRef = useRef(false)
  const handleOnList = useMemoizedFn((list: IMerchant[]) => onList?.(list))

  const {
    loading,
    data,
    run: getList,
    mutate,
  } = useRequest<IMerchant[], []>(() => apiGetOptions(), {
    manual: true,
    cacheKey: 'components/MerchantSelect',
    cacheTime: -1,
    staleTime: 1000 * 1, // 该时间内重复调用时不请求，直接认为缓存是新鲜的
    onError: () => void mutate(undefined),
  })

  useEffect(() => {
    if (!initialFlagRef.current) {
      getList()
      initialFlagRef.current = true
    }
  }, [getList])

  useEffect(() => {
    if (data) handleOnList(data)
  }, [data, handleOnList])

  return (
    <Select
      allowClear
      {...rest}
      style={{ minWidth: 110, maxWidth: !multiple ? 200 : undefined, ...style }}
      className={className}
      showSearch={true}
      filterOption={selectFilterOption}
      placeholder={
        placeholder ||
        (multiple ? (
          <span style={{ color: token.colorTextBase }}>{t('6-comp.quan-bu-shang-jia')}</span>
        ) : (
          t('6-comp.qing-xuan-ze-shang-jia')
        ))
      }
      mode={multiple ? 'multiple' : undefined}
      maxTagCount={maxTagCount || 1}
      maxTagTextLength={maxTagTextLength || 5}
      popupMatchSelectWidth={false}
      notFoundContent={loading ? <Loading delay={0} /> : undefined}
      options={data?.map(d => ({
        value: d.userId,
        label: getOptionLabel ? getOptionLabel(d) : d.userName || d.userId,
      }))}
      onDropdownVisibleChange={visible => {
        if (visible && !initialFlagRef.current) {
          initialFlagRef.current = true
          getList()
        }
      }}
      value={value || undefined}
      onChange={val => {
        onChange?.(val)
      }}
    />
  )
}
