import { ReactNode } from 'react'
import { Navigate, RouteObject } from 'react-router-dom'
import { t } from 'i18next'

import { PageEmpty } from '@/components/PageEmpty'
import { RouteErrorBoundary } from '@/components/RouteErrorBoundary'
import { fetchUserInfo } from '@/globalStore/userInfo'
import { Auth } from './Auth'
import { SetTitle } from './SetTitle'

type THandle = {
  name: () => string
  hideLeftSider?: boolean
  hideBreadcrumb?: boolean
  noLayout?: boolean
}

type TRoute<TRequiredHandle = false> = Omit<RouteObject, 'handle' | 'index'> & {
  path: string
  children?: TRoute<true>[]
} & (TRequiredHandle extends true ? { handle: THandle } : { handle?: THandle })

/** 设置标题，只需作用于根路由即可 */
const setTitle = (element: ReactNode) => <SetTitle>{element}</SetTitle>

/** 权限控制 */
const auth = (element: ReactNode, codes: [string, ...string[]]) => <Auth codes={codes}>{element}</Auth>

/** 404 路由 */
const notFoundRoute: TRoute<true> = {
  path: '*',
  handle: { name: () => t('1-error.notFound') },
  element: setTitle(<PageEmpty type="notFound" />),
}

/** 路由表，集中统一配置，路由地址使用首字母小写驼峰命名法 */
let routes: TRoute[] = [
  {
    path: '/',
    element: <Navigate to={import.meta.env.VITE_APP_HOME_ROUTE_PATH} replace />,
  },
  {
    path: '/login/:pageType?', // register:注册 | passwordReset:密码重置
    handle: { name: () => t('9-nav.deng-lu-zhu-ce-mi-ma-zhong-zhi') },
    lazy: async () => {
      const { Login } = await import('@/pages/Login')
      return { element: setTitle(<Login />) }
    },
  },
  {
    path: '/biz/',
    loader: () => fetchUserInfo().then(() => null),
    lazy: async () => {
      const { Biz } = await import('@/pages/Biz/Biz')
      return { element: setTitle(<Biz />) }
    },
    children: [
      // ======================
      // ====== 商家侧 ======
      {
        path: '/biz/nonVIP',
        handle: { name: () => t('0-app.name'), hideLeftSider: true, hideBreadcrumb: true },
        lazy: async () => {
          const { NonVIP } = await import('@/pages/Biz/NonVIP')
          return { element: auth(<NonVIP />, ['102']) }
        },
      },

      {
        path: '/biz/order/list',
        handle: { name: () => t('9-nav.ding-dan-lie-biao') },
        lazy: async () => {
          const { OrderList } = await import('@/pages/Biz/Order')
          return { element: auth(<OrderList />, ['102']) }
        },
      },
      {
        path: '/biz/order/inFulfillmen',
        handle: { name: () => t('9-nav.yun-dan') },
        lazy: async () => {
          const { OrderInFulfillmen } = await import('@/pages/Biz/Order')
          return { element: auth(<OrderInFulfillmen />, ['102']) }
        },
      },
      {
        path: '/biz/order/track',
        handle: { name: () => t('9-nav.ding-dan-zhui-zong') },
        lazy: async () => {
          const { OrderTrack } = await import('@/pages/Biz/Order')
          return { element: auth(<OrderTrack />, ['102']) }
        },
      },
      {
        path: '/biz/order/error',
        handle: { name: () => t('9-nav.yi-chang-ding-dan') },
        lazy: async () => {
          const { OrderError } = await import('@/pages/Biz/Order')
          return { element: auth(<OrderError />, ['102']) }
        },
      },

      {
        path: '/biz/shop/authorizationResult',
        handle: {
          name: () => t('9-nav.dian-pu-shou-quan-jie-guo'),
          hideLeftSider: true,
          hideBreadcrumb: true,
        },
        lazy: async () => {
          const { ShopAuthorizationResult } = await import('@/pages/Biz/ShopList')
          return { element: auth(<ShopAuthorizationResult />, ['102']) }
        },
      },
      {
        path: '/biz/shop/list',
        handle: { name: () => t('9-nav.dian-pu-lie-biao') },
        lazy: async () => {
          const { ShopList } = await import('@/pages/Biz/ShopList')
          return { element: auth(<ShopList />, ['102']) }
        },
      },
      {
        path: '/biz/shop/productMatch',
        handle: { name: () => t('9-nav.shang-pin-pi-pei') },
        lazy: async () => {
          const { ProductMatch } = await import('@/pages/Biz/ProductMatch')
          return { element: auth(<ProductMatch />, ['102']) }
        },
      },
      {
        path: '/biz/shop/localProductList',
        handle: { name: () => t('9-nav.ben-di-chan-pin') },
        lazy: async () => {
          const { LocalProductList } = await import('@/pages/Biz/LocalProductList')
          return { element: auth(<LocalProductList />, ['102']) }
        },
      },

      {
        path: '/biz/logistics/list',
        handle: { name: () => t('9-nav.wu-liu-guan-li') },
        lazy: async () => {
          const { List } = await import('@/pages/Biz/Logistics')
          return { element: auth(<List />, ['102']) }
        },
      },

      {
        path: '/biz/warehouse/stock',
        handle: { name: () => t('9-nav.ku-cun-lie-biao') },
        lazy: async () => {
          const { Stock } = await import('@/pages/Biz/Warehouse')
          return { element: auth(<Stock />, ['102']) }
        },
      },
      {
        path: '/biz/warehouse/outOfStock',
        handle: { name: () => t('9-nav.que-huo-ku-cun-lie-biao') },
        lazy: async () => {
          const { OutOfStock } = await import('@/pages/Biz/Warehouse')
          return { element: auth(<OutOfStock />, ['102']) }
        },
      },
      {
        path: '/biz/warehouse/stockFlow',
        handle: { name: () => t('9-nav.ku-cun-liu-shui') },
        lazy: async () => {
          const { StockFlow } = await import('@/pages/Biz/Warehouse')
          return { element: auth(<StockFlow />, ['102']) }
        },
      },

      {
        path: '/biz/delivery',
        handle: { name: () => t('9-nav.ti-huo-guan-li') },
        lazy: async () => {
          const { Delivery } = await import('@/pages/Biz/Delivery')
          return { element: auth(<Delivery />, ['102']) }
        },
      },

      {
        path: '/biz/supplier',
        handle: { name: () => t('9-nav.gong-ying-shang-guan-li') },
        lazy: async () => {
          const { Supplier } = await import('@/pages/Biz/Supplier')
          return { element: auth(<Supplier />, ['102']) }
        },
      },

      {
        path: '/biz/pkgMaterial',
        handle: { name: () => t('9-nav.bao-cai-guan-li') },
        lazy: async () => {
          const { WrapProductList } = await import('@/pages/Biz/LocalProductList')
          return { element: auth(<WrapProductList />, ['102']) }
        },
      },

      {
        path: '/biz/transaction/recharge',
        handle: { name: () => t('9-nav.zhang-hu-chong-zhi') },
        lazy: async () => {
          const { Recharge } = await import('@/pages/Biz/Transaction')
          return { element: auth(<Recharge />, ['102']) }
        },
      },
      {
        path: '/biz/transaction/accountFlow',
        handle: { name: () => t('9-nav.zi-jin-liu-shui') },
        lazy: async () => {
          const { AccountFlow } = await import('@/pages/Biz/Transaction')
          return { element: auth(<AccountFlow />, ['102']) }
        },
      },
      {
        path: '/biz/transaction/bill',
        handle: { name: () => t('9-nav.wo-de-zhang-dan') },
        lazy: async () => {
          const { Bill } = await import('@/pages/Biz/Transaction')
          return { element: auth(<Bill />, ['102']) }
        },
      },

      {
        path: '/biz/setting/billing',
        handle: { name: () => 'Billing' },
        lazy: async () => {
          const { Billing } = await import('@/pages/Biz/Setting')
          return { element: auth(<Billing />, ['102']) }
        },
      },

      // ======================
      // ====== 员工侧 ======
      {
        path: '/biz/admin/whiteList',
        handle: { name: () => t('9-nav.bai-ming-dan-guan-li') },
        lazy: async () => {
          const { WhiteList } = await import('@/pages/Biz/WhiteList')
          return { element: auth(<WhiteList />, ['100', '101']) }
        },
      },

      {
        path: '/biz/admin/order/audit',
        handle: { name: () => t('9-nav.lv-yue-dan-shen-he') },
        lazy: async () => {
          const { AdminOrderAudit } = await import('@/pages/Biz/Order')
          return { element: auth(<AdminOrderAudit />, ['101']) }
        },
      },
      {
        path: '/biz/admin/order/track',
        handle: { name: () => t('9-nav.yun-dan-zhui-zong') },
        lazy: async () => {
          const { AdminOrderTrack } = await import('@/pages/Biz/Order')
          return { element: auth(<AdminOrderTrack />, ['101']) }
        },
      },
      {
        path: '/biz/admin/order/error',
        handle: { name: () => t('9-nav.yi-chang-lv-yue-dan') },
        lazy: async () => {
          const { AdminOrderError } = await import('@/pages/Biz/Order')
          return { element: auth(<AdminOrderError />, ['101']) }
        },
      },

      {
        path: '/biz/admin/shop/localProductList',
        handle: { name: () => t('9-nav.ben-di-chan-pin') },
        lazy: async () => {
          const { LocalProductList } = await import('@/pages/Biz/LocalProductList')
          return { element: auth(<LocalProductList isEmployee />, ['101']) }
        },
      },

      {
        path: '/biz/admin/logistics/list',
        handle: { name: () => t('9-nav.wu-liu-lie-biao') },
        lazy: async () => {
          const { List } = await import('@/pages/Biz/Logistics')
          return { element: auth(<List isEmployee />, ['101']) }
        },
      },
      {
        path: '/biz/admin/logistics/freightTemplates',
        handle: { name: () => t('9-nav.yun-fei-mo-ban') },
        lazy: async () => {
          const { FreightTemplate } = await import('@/pages/Biz/Logistics')
          return { element: auth(<FreightTemplate />, ['101']) }
        },
      },

      {
        path: '/biz/admin/warehouse/stock',
        handle: { name: () => t('9-nav.ku-cun-lie-biao') },
        lazy: async () => {
          const { Stock } = await import('@/pages/Biz/Warehouse')
          return { element: auth(<Stock isEmployee />, ['101']) }
        },
      },
      {
        path: '/biz/admin/warehouse/outOfStock',
        handle: { name: () => t('9-nav.que-huo-ku-cun-lie-biao') },
        lazy: async () => {
          const { OutOfStock } = await import('@/pages/Biz/Warehouse')
          return { element: auth(<OutOfStock isEmployee />, ['101']) }
        },
      },
      {
        path: '/biz/admin/warehouse/stockFlow',
        handle: { name: () => t('9-nav.ku-cun-liu-shui') },
        lazy: async () => {
          const { StockFlow } = await import('@/pages/Biz/Warehouse')
          return { element: auth(<StockFlow isEmployee />, ['101']) }
        },
      },

      {
        path: '/biz/admin/delivery',
        handle: { name: () => t('9-nav.ti-huo-guan-li') },
        lazy: async () => {
          const { Delivery } = await import('@/pages/Biz/Delivery')
          return { element: auth(<Delivery isEmployee />, ['101']) }
        },
      },

      {
        path: '/biz/admin/pkgMaterial',
        handle: { name: () => t('9-nav.bao-cai-guan-li') },
        lazy: async () => {
          const { WrapProductList } = await import('@/pages/Biz/LocalProductList')
          return { element: auth(<WrapProductList isEmployee />, ['101']) }
        },
      },

      {
        path: '/biz/admin/transaction/recharge',
        handle: { name: () => t('9-nav.zhang-hu-chong-zhi') },
        lazy: async () => {
          const { Recharge } = await import('@/pages/Biz/Transaction')
          return { element: auth(<Recharge />, ['101']) }
        },
      },
      {
        path: '/biz/admin/transaction/accountFlow',
        handle: { name: () => t('9-nav.zi-jin-liu-shui') },
        lazy: async () => {
          const { AccountFlow } = await import('@/pages/Biz/Transaction')
          return { element: auth(<AccountFlow />, ['101']) }
        },
      },
      {
        path: '/biz/admin/transaction/bill',
        handle: { name: () => t('9-nav.zhang-dan-lie-biao') },
        lazy: async () => {
          const { Bill } = await import('@/pages/Biz/Transaction')
          return { element: auth(<Bill />, ['101']) }
        },
      },
      {
        path: '/biz/admin/transaction/bankStatement',
        handle: { name: () => t('9-nav.yin-hang-liu-shui') },
        lazy: async () => {
          const { BankStatement } = await import('@/pages/Biz/Transaction')
          return { element: auth(<BankStatement />, ['101']) }
        },
      },

      // ======================
      // ====== 财务侧 ======
      {
        path: '/biz/finance/transaction/recharge',
        handle: { name: () => t('9-nav.chong-zhi-shen-he') },
        lazy: async () => {
          const { Recharge } = await import('@/pages/Biz/Transaction')
          return { element: auth(<Recharge />, ['103']) }
        },
      },
      {
        path: '/biz/finance/transaction/bankStatement',
        handle: { name: () => t('9-nav.yin-hang-liu-shui') },
        lazy: async () => {
          const { BankStatement } = await import('@/pages/Biz/Transaction')
          return { element: auth(<BankStatement />, ['103']) }
        },
      },

      // ======================
      // ====== 公共 ======
      {
        path: '/biz/transaction/bill/detail/:invoiceId/:merchantUserId',
        handle: { noLayout: true, name: () => t('9-nav.zhang-dan-xiang-qing') },
        lazy: async () => {
          const { Detail } = await import('@/pages/Biz/Transaction')
          return { element: auth(<Detail />, ['101', '102']) }
        },
      },

      // ======================
      // ====== 仓库侧 ======
      {
        path: '/biz/store/shop/localProductList',
        handle: { name: () => t('9-nav.ben-di-chan-pin') },
        lazy: async () => {
          const { LocalProductList } = await import('@/pages/Biz/LocalProductList')
          return { element: auth(<LocalProductList isEmployee isEmployeeStore />, ['104']) }
        },
      },
      {
        path: '/biz/store/pkgMaterial',
        handle: { name: () => t('9-nav.bao-cai-guan-li') },
        lazy: async () => {
          const { WrapProductList } = await import('@/pages/Biz/LocalProductList')
          return { element: auth(<WrapProductList isEmployee isEmployeeStore />, ['104']) }
        },
      },

      { ...notFoundRoute },
    ],
  },
  { ...notFoundRoute },
]

// 路由表公共逻辑处理
routes = routes.map(item => {
  const loop = function <T>({ ...route }: TRoute<T>): TRoute<T> {
    if (!route.errorElement) route.errorElement = <RouteErrorBoundary /> // 异常反馈
    if (route.children) {
      route.children = route.children.map(loop)
    }
    return route
  }
  return loop(item)
})

export { routes }
