export const STORAGE_KEY_LANG = 'oms_lang'

export const _setAppLangToLocalStorage = (value: 'zh' | 'en') => {
  if (value === 'zh' || value === 'en') {
    localStorage.setItem(STORAGE_KEY_LANG, value)
    document.documentElement.setAttribute('lang', value === 'zh' ? 'zh-CN' : 'en')
  }
}

/** 从 localStorage 读取语言 */
export const getAppLangByLocalStorage = (type?: 'initialGlobalState'): 'zh' | 'en' => {
  let lang: 'zh' | 'en'

  const localValue = localStorage.getItem(STORAGE_KEY_LANG)
  if (localValue === 'zh' || localValue === 'en') {
    lang = localValue
  } else {
    lang = navigator.language === 'zh-CN' ? 'zh' : 'en'
  }

  if (type === 'initialGlobalState') {
    document.documentElement.setAttribute('lang', lang === 'zh' ? 'zh-CN' : 'en')
  }

  return lang
}
