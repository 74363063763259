const STORAGE_KEY_TOKEN_NAME = 'oms_tokenName'
const STORAGE_KEY_TOKEN_VALUE = 'oms_tokenValue'

export const getToken = (): [key: string, value: string] | null => {
  const tokenName = localStorage.getItem(STORAGE_KEY_TOKEN_NAME)
  const tokenValue = localStorage.getItem(STORAGE_KEY_TOKEN_VALUE)

  if (tokenName && tokenValue) {
    return [tokenName, `linkhubBearer ${tokenValue}`]
  }

  return null
}

export const saveToken = ({ tokenName, tokenValue }: { tokenName: string; tokenValue: string }) => {
  localStorage.setItem(STORAGE_KEY_TOKEN_NAME, tokenName)
  localStorage.setItem(STORAGE_KEY_TOKEN_VALUE, tokenValue)
}

export const removeToken = () => {
  localStorage.removeItem(STORAGE_KEY_TOKEN_NAME)
  localStorage.removeItem(STORAGE_KEY_TOKEN_VALUE)
}
