import { useState } from 'react'
import { Navigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Button, Result } from 'antd'

import { useGlobalState } from '@/globalStore/globalStore'
import { apiApply } from './apis'

let appliedFlag = false

export const NonVIP = () => {
  const userInfo = useGlobalState(s => s.userInfo!)
  const vip = !!userInfo.roleCodeMap['102'] && !!userInfo.vip

  const [applied, setApplied] = useState(appliedFlag)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  if (vip) {
    return <Navigate to="/" replace />
  }

  if (applied) {
    return <Result status="success" title={t('NonVip.applySuccessful')} subTitle={t('NonVip.applyTips')} />
  }

  return (
    <Result
      title={t('NonVip.applyText')}
      extra={
        <Button
          style={{ minWidth: 250 }}
          size="large"
          type="primary"
          loading={loading}
          onClick={async () => {
            try {
              setLoading(true)
              await apiApply()
              setApplied(true)
              appliedFlag = true
            } finally {
              setLoading(false)
            }
          }}
        >
          {t('NonVip.applyNow')}
        </Button>
      }
    />
  )
}
