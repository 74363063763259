/* eslint-disable simple-import-sort/imports */
// 依据 node_modules/.vite/deps/*.js，@chunks稳定性
import i18next, {
  changeLanguage,
  createInstance,
  dir,
  exists,
  getFixedT,
  hasLoadedNamespace,
  init,
  loadLanguages,
  loadNamespaces,
  loadResources,
  reloadResources,
  setDefaultNamespace,
  t,
  use,
} from 'i18next'

export default {
  i18next,
  changeLanguage,
  createInstance,
  dir,
  exists,
  getFixedT,
  hasLoadedNamespace,
  init,
  loadLanguages,
  loadNamespaces,
  loadResources,
  reloadResources,
  setDefaultNamespace,
  t,
  use,
}
