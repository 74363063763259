/* eslint-disable simple-import-sort/imports */
// 依据 node_modules/.vite/deps/*.js，@chunks稳定性
import axios, {
  Axios,
  AxiosError,
  AxiosHeaders,
  Cancel,
  CancelToken,
  CanceledError,
  HttpStatusCode,
  VERSION,
  all,
  formToJSON,
  getAdapter,
  isAxiosError,
  isCancel,
  mergeConfig,
  spread,
  toFormData,
} from 'axios'

export default {
  axios,
  Axios,
  AxiosError,
  AxiosHeaders,
  Cancel,
  CancelToken,
  CanceledError,
  HttpStatusCode,
  VERSION,
  all,
  formToJSON,
  getAdapter,
  isAxiosError,
  isCancel,
  mergeConfig,
  spread,
  toFormData,
}
